export const PROJECT_NAME = 'Sameh Mall';
export const BASE_URL = 'https://api.sameh-mall.com';
export const WEBSITE_URL = 'https://sameh-mall.com';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const export_url = BASE_URL + '/storage/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyDo4R8pPtJTtyT4F9budtA4BnOm8ZcxDPE';
export const VAPID_KEY =
  'BNhxIRZ2KktTLj2Dckd7CzSsb7ZaekDpVuKlEaEZKZiY1M357ZbDRIVW_QdmTVe7K0QYcGjwcqIzaH8F9r6mVS8';
export const STRIPE_PUBLISHABLE_KEY =
  'pk_test_51LaGXyLrMlc0ddAAYc8wxwq8eFj9HbgpvValjxRtg2OZzTJDwFz0ZmATL1TOqAWUFBM3CeHbI5Cny71MzkrAXRxa00XTCJArPr';
export const PAYPAL_KEY =
  'ARKhb_cfiyLAGikamzpXnWEiynGxcGeOkmuxWQ9i1CUztdQy3F_UT4C7vbtR6sfPQ3kxecBi_NL_UEDF';
export const PAYSTACK_KEY = 'pk_test_2ec2bc5ed445385489385b2fce6cddfbfb265914';
export const RAZORPAY_KEY = 'rzp_test_TMPwvQpYGAIMbU';
export const StripeApi = 'https://sundaymart.net/api/create-stripe-session';
export const RazorpayApi = 'https://sundaymart.net/api/create-razorpay-session';

export const API_KEY = 'AIzaSyDo4R8pPtJTtyT4F9budtA4BnOm8ZcxDPE';
export const AUTH_DOMAIN = 'sameh-mall-2c072.firebaseapp.com';
export const PROJECT_ID = 'sameh-mall-2c072';
export const STORAGE_BUCKET = 'sameh-mall-2c072.appspot.com';
export const MESSAGING_SENDER_ID = '470734001235';
export const APP_ID = '1:470734001235:web:004d6e76eabd2bc9a9e2a6';
export const MEASUREMENT_ID = 'G-5N2Z330HV3';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];

export const DEMO_SELLER = 210; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id
